import * as React from 'react';
import * as styles from './offerItem.module.scss';
import * as offerStyles from '../../pages/offer.module.scss';
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import linkPrettier from "../../helpers/linkPrettier"

interface IOfferItem {
  title: string,
  slug: string,
  description: {raw: string},
  image: string,
  isEven: boolean,
}

const OfferItem: React.FC<IOfferItem> = ({title, slug, description, image, isEven}: IOfferItem) => (
  <div className={styles.container} id={slug}>
    <h6 className={offerStyles.offerHeader}>{title}</h6>
    <div className={styles.row}>
      <img src={image} className={`${styles.offerImage} ${isEven ? styles.rowItemFirst : ''}`} />
      <div dangerouslySetInnerHTML={{ __html: documentToHtmlString(JSON.parse(description.raw)) }} className={styles.offerDescription}/>
    </div>
  </div>
)

export default OfferItem