import * as React from "react";
import { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import * as styles from './offer.module.scss';
import ApartmentCard, { IApartmentCard } from "../components/ApartmentCard/ApartmentCard"
import OfferItem from "../components/OfferItem/OfferItem"
import { IOfferSet } from "../types/contentfulTypes";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import CalendarModal from "../components/CalendarModal/CalendarModal"
import CalendarButton from "../components/CalendarButton/CalendarButton"

type Props = {
  data: {
    offersData: IOfferSet,
  },
  location: any,
}

export const query = graphql`
  query($locale: String) {
    offersData: contentfulOffersSet(
      slug: { eq: "main" }
      node_locale: { eq: $locale }
    ) {
      apartmentsHeader,
      apartmentsSlug,
      apartmentsComment {
        raw
      },
      offerItems {
        title
        slug
        image {
          file {
            url
          }
        }
        description {
          raw
        }
      }
      apartments {
        name
        photos {
          file {
            url
          }
        },
        slug
        prices {
          season {
            name
          }
          minPrice
        }
      }
    }
  }
`

const Offer: React.FC<Props> = ({data: {offersData}, location}) => {
  const apartments = offersData.apartments;
  const offers = offersData.offerItems.map(i => ({...i, image: i.image.file.url}));

  const [rendered, setRendered] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);

  useEffect(() => {
    setRendered(true);
  }, [rendered])

  useEffect(() => {
    if (rendered && imagesLoaded) {
      const sectionId = location.hash && location.hash.slice(1,);
      const requestedSection = sectionId && document.getElementById(sectionId);
      requestedSection && requestedSection.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }, [rendered, imagesLoaded])

  const filterApartmentData = (slug: string): IApartmentCard => {
    const apartment = apartments.find(i => i.slug === slug);
    return {
      slug: slug,
      imageUrl: apartment.photos[0].file.url,
      name: apartment.name,
      pricing: apartment.prices.map((apartmentPrice) => ({
        seasonName: apartmentPrice.season.name,
        minPrice: apartmentPrice.minPrice,
      })),
    };
  }

  const imagesToPreload = [...apartments.map(i => i.photos[0].file.url), ...offers.map(j => j.image)]
  const apartmentsComment = documentToHtmlString(JSON.parse(offersData.apartmentsComment.raw));

  return (
    <Layout imagesToPreload={imagesToPreload} onLoadCallback={() => {setImagesLoaded(true)}}>
      <div className={styles.offerContainer}>
        <div className={styles.contentWrapper}>
          <h6 className={styles.offerHeader} id={offersData.apartmentsSlug}>{offersData.apartmentsHeader}</h6>
          <div className={styles.cardsContainer}>
            {apartments.map(item => <ApartmentCard {...filterApartmentData(item.slug)}/> )}
          </div>
          <div className={styles.offerDescription} dangerouslySetInnerHTML={{ __html: apartmentsComment}} />
          <CalendarButton
            onClick={() => {
              setCalendarVisible(true)
              document.body.classList.add('scrollDisabled');
            }}
            className={styles.offerButton}
          />
        </div>
        {offers.map((offer, index) => <div className={styles.contentWrapper}><OfferItem {...offer} isEven={(index) % 2 === 0}/></div>)}
      </div>
      {calendarVisible && <CalendarModal onClose={() => {setCalendarVisible(false)}} /> }
    </Layout>
  )
}

export default Offer
