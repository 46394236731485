import * as React from 'react';
import { FormattedMessage, Link } from "gatsby-plugin-intl"
import * as styles from "./apartmentCard.module.scss";

export interface IApartmentCard {
  slug: string,
  imageUrl: string,
  name: string,
  pricing: {
    seasonName: string,
    minPrice: number,
  }[],
}

const ApartmentCard: React.FC<IApartmentCard> = ({slug, imageUrl, name, pricing}) => {
  return (
    <Link className={styles.apartmentCard} to={`/apartment/${slug}/`}>
      <div className={styles.cardImage} style={{backgroundImage: `url(${imageUrl})`}}/>
      <div className={styles.cardInfo}>
        <div className={styles.cardTitle}>{name}</div>
        <div className={styles.cardDetails}>
          <table className={styles.pricing}>
            {pricing.map(item => (
              <tr className={styles.pricingItem}>
                <td>{item.seasonName}</td>
                <td>
                  <FormattedMessage
                    id="price_box__minimum_price"
                    description="Minimum apartment price"
                    values={{value: item.minPrice}}
                  />
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </Link>
  )
}

export default ApartmentCard
